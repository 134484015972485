import React from "react";
import "../styles.css";
import lockImage from "../assets/mainBlock.jpg"; // Картинка замка

const EmergencyUnlock = () => {
  return (
    <section className="emergency-unlock">
      <div className="emergency-content">
        <h2>Починим пластиковое окно без лишних хлопот</h2>
        <p>
          Оперативно устраняем поломки пластиковых окон в квартирах, офисах и домах.<br />
          Наши мастера приезжают в течение 30–60 минут, находят причину неисправности и быстро всё чинят — без шума и лишней суеты.<br />

          Если окно не закрывается, заедает ручка или дует из щелей — не тяните,  <span className="highlight"> просто позвоните нам.</span>. Приедем, починим, и всё снова будет работать как надо.
        </p>

        <a href="tel:+79654757788" className="highlight">+7 (965) 475-77-88</a>

      </div>
      <img src={lockImage} alt="Открытие замка" className="emergency-image" />
    </section>
  );
};

export default EmergencyUnlock;
