import React from "react";
import "../styles.css";
import masterPhoto from "../assets/masters.png"; // Фотография мастера

const AboutUs = () => {
  return (
    <section className="about-us">
      <h2 className="about-title">О НАС</h2>

      {/* Блок с преимуществами */}
      <div className="advantages">
        <div className="advantage">
          <span>более 25 ЛЕТ</span>
          <p>Работаем с замками и дверьми</p>
        </div>
        <div className="advantage">
          <span>СВЫШЕ 15 000</span>
          <p>Довольных клиентов</p>
        </div>
        <div className="advantage">
          <span>ЧЕК И ГАРАНТИЯ</span>
          <p>На работу и замки</p>
        </div>
        <div className="advantage">
          <span>ЛУЧШАЯ ОСНАСТКА</span>
          <p>Современный инструмент</p>
        </div>
      </div>

      {/* Блок с фото и текстом */}
      <div className="about-content">
        <div className="about-text">
          <p>
           <strong> Мы работаем с 1999 года</strong> оказывая услуги по ремонту, регулировке и обслуживанию пластиковых окон.<br />
            Устанавливаем москитные сетки всех видов,<strong> устраняем сквозняки, проблемы с фурнитурой, ручками и уплотнителем</strong>.<br />
            Проводим замену стеклопакетов, восстановление герметичности и утепление окон.<br />
            Работаем аккуратно, быстро и с гарантией.
          </p>
          <p>
            <span className="highlight"> 🕒 Выезд мастера в течение 1 часа. Работаем ежедневно, без выходных.</span>
          </p>
        </div>
        <img src={masterPhoto} alt="Мастер" className="master-photo" />
      </div>
    </section>
  );
};

export default AboutUs;
