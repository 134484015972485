import React from "react";
import "../styles.css";
import { FaPhoneAlt, FaWhatsapp, FaTelegramPlane } from "react-icons/fa";

const Contacts = () => {
  const phoneNumber = "9654757788";
  const formattedPhone = "+7 (965) 475-77-88";

  return (
    <section className="contacts">
      <h2>Свяжитесь с нами</h2>
      <p>Мы всегда на связи! Позвоните или напишите в удобный мессенджер.</p>

      <div className="contact-buttons">
        {/* Телефон */}
        <a href={`tel:+7${phoneNumber}`} className="contact-btn phone">
          <FaPhoneAlt /> {formattedPhone}
        </a>

        {/* WhatsApp */}
        <a href={`https://wa.me/7${phoneNumber}`} target="_blank" rel="noopener noreferrer" className="contact-btn whatsapp">
          <FaWhatsapp /> WhatsApp
        </a>

        {/* Telegram */}
        <a href={`https://t.me/Remontokonkrasnodar`} target="_blank" rel="noopener noreferrer" className="contact-btn telegram">
          <FaTelegramPlane /> Telegram
        </a>
      </div>
    </section>
  );
};

export default Contacts;
