import React from "react";
import "../styles.css";
import { FaPhone,} from "react-icons/fa";
import { GiWindow } from 'react-icons/gi';
import { MdBuild, MdPayment } from 'react-icons/md';
import { RiToolsFill } from 'react-icons/ri';

const steps = [
  {
    id: 1,
    icon: <FaPhone />,
    title: "Звоните нам",
    description: "Оставьте заявку или позвоните по телефону.",
    highlight: <a href="tel:+79654757788">+7 (965) 475-77-88</a>
  },
  { id: 2, icon: <GiWindow />, title: "Приезд мастера", description: "Мастер приедет к вам в течение 60 минут." },
  { id: 3, icon: <MdBuild />, title: "Диагностика и оценка работы", description: "Окно осматривается и оценивается работа" },
  { id: 4, icon: <RiToolsFill />, title: "Проведение работы", description: "Делаем все аккуратно, чтобы ничего не повредить." },
  { id: 5, icon:  <MdPayment />, title: "Оплата ", description: "Проводится оплата" }
];

const HowWeWork = () => {
  return (
    <section className="how-we-work">
      <h2>Как мы работаем – 5 простых шагов</h2>

      <div className="steps-wrapper">
        {/* SVG Волнистая линия (скрываем на мобилках) */}
        <svg className="wavy-line" viewBox="0 0 1000 120" preserveAspectRatio="none">
          <path className="wavy-path" d="M0,60 Q200,0 400,60 T800,60 T1000,60" />
        </svg>

        {/* Контейнер шагов с горизонтальным скроллом */}
        <div className="steps-container" >
          {steps.map((step) => (
            <div key={step.id} className="step-card">
              <div className="step-icon">{step.icon}</div>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
              {step.highlight && <p className="highlight">{step.highlight}</p>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
