import React, { useState, useEffect } from "react";
import { FaPhone, FaTelegramPlane, FaWhatsapp, FaTimes, FaCommentDots } from "react-icons/fa";
import "../styles.css";

const FloatingContacts = () => {
  const [visible, setVisible] = useState(false); // Кнопка появляется при скролле вниз
  const [expanded, setExpanded] = useState(false); // Открыто ли меню контактов

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setVisible(true); // Показать кнопку после прокрутки 300px
      } else {
        setVisible(false); // Скрыть кнопку при возврате вверх
        setExpanded(false); // Закрыть контакты
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`floating-container ${expanded ? "expanded" : ""}`}>
      {/* Кнопка "Контакты" */}
      <button className={`floating-button ${visible ? "show" : ""}`} onClick={() => setExpanded(!expanded)}>
        {expanded ? <FaTimes /> : <FaCommentDots />}
      </button>

      {/* Выпадающее меню с контактами */}
      {expanded && (
        <div className="contact-box">
          <a href="tel:+79654757788" className="contact-link">
            <FaPhone /> +7 (965) 475-77-88
          </a>
          <a href="https://wa.me/79654757788" target="_blank" rel="noopener noreferrer" className="contact-link">
            <FaWhatsapp /> WhatsApp
          </a>
          <a href="https://t.me/Remontokonkrasnodar" target="_blank" rel="noopener noreferrer" className="contact-link">
            <FaTelegramPlane /> Telegram
          </a>
        </div>
      )}
    </div>
  );
};

export default FloatingContacts;
