import React from "react";
import "./Main.css";

// Импорт изображений
import logo from "../assets/logoKras.png";
import background from "../assets/main1.png";
import overlay from "../assets/main2.png";
import info1 from "../assets/block1.jpg";
import info2 from "../assets/block2.webp";

const indicators = [
  {
    id: 1,
    top: "40%",
    left: "30%",
    title: "Ремонт пластиковые окна",
    description: "Предаставляем услуги по ремонту и обслуживанию",
    image: info1,
    target: "windowRepair",
  },
  {
    id: 2,
    top: "60%",
    left: "70%",
    title: "Москитные сетки",
    description: "Большой выбор по хорошей цене",
    image: info2,
    target: "mosquitoNets",
  },
];
const Main = ({ onIndicatorClick = () => {} }) => {
  return (
    <div className="main">
     <img src={logo} alt="Фон" className="logo"           />
      <h1>РЕМОНТ ПЛАСТИКОВЫХ ОКОН В КРАСНОДАРЕ</h1>
      <div className="image-container">
        <img src={background} alt="Фон" className="image background-image" />
        <img src={overlay} alt="Наложение" className="image overlay-image" />

        {indicators.map((indicator) => (
          <div
            key={indicator.id}
            className="interactive-point"
            style={{ top: indicator.top, left: indicator.left }}
          >
            <div className="interactive-point__dot"></div>
            <div className="interactive-point__tooltip">
              <img src={indicator.image} alt={indicator.title} />
              <h3>{indicator.title}</h3>
              <p>{indicator.description}</p>
              <button onClick={() => onIndicatorClick(indicator.target)}>Каталог</button>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


export default Main;
