import React from "react";
import "../styles.css";
import { FaShieldAlt, FaClock, FaCertificate, FaUserShield, FaHandsHelping } from "react-icons/fa";

const trustFactors = [
  {
    icon: <FaShieldAlt />,
    title: "Надежность и профессионализм",
    description: "Работаем аккуратно и по стандартам. Уважение к вашему времени и дому — наш приоритет.",
  },
  {
    icon: <FaClock />,
    title: "Быстро и доступно",
    description: "Приезд мастера в течение часа. Прозрачные цены, никаких скрытых затрат.",
  },
  {
    icon: <FaCertificate />,
    title: "Опыт более 25 лет",
    description: "Более 15 000 довольных клиентов. Только сертифицированные специалисты.",
  },
  {
    icon: <FaUserShield  />,
    title: "Уважение к личному пространству",
    description: "Минимум шума и мусора. После ремонта всё оставляем в чистоте.",
  },
  {
    icon: <FaHandsHelping />,
    title: "Гарантия на все работы",
    description: "Используем только качественные комплектующие. Даём гарантию на ремонт и установку.",
  }
];

const WhyUs = () => {
  return (
    <section className="why-us">
      <h2 className="why-us-title">Почему выбирают нас?</h2>
      <div className="why-us-container">
        {trustFactors.map((factor, index) => (
          <div key={index} className="why-us-card">
            <div className="why-us-icon">{factor.icon}</div>
            <h3>{factor.title}</h3>
            <p>{factor.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyUs;
