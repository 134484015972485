import React, { useState, forwardRef } from "react";

import "./WindowRepair.css";
import Ramochnie from "../assets/Dver.jpg"
const categories = [
  {
    id: 1,
    title: "регулировка пластиковых окон",
    content: (
      <>
        {/* Условия выезда */}
        <div className="conditions">
          <h3>Условия выезда</h3>
          <p>✔️ Стоимость выезда мастера – <b>3 000 руб.</b> (бесплатно при заключении договора).</p>
          <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
        </div>


        {/* Описание и фото */}
        <div className="description">
          <img src={Ramochnie} alt="Рамочная москитная сетка" className="main-image" />
          <p>
            Осуществляем регулировку пластиковых окон по цене от 350 руб.<br />
            <strong>Устраняем все виды неполадок </strong>, производим замену фурнитуры и механизмов для ПВХ окон и дверей в Краснодаре и Краснодарском крае.
          </p>

        </div>


        <div className="description1">
          <h2>
            Регулировка пластиковых (ПВХ) окон в Краснодаре
          </h2>
          <p>
            Профилактика и регулировка окон пвх обычно включает в себя следующие процедуры:<br />

            <br />• восстановление геометрии окна;
            <br />• герметизация стыков;
            <br /> • смазка фурнитуры и подвижных частей окна;
            <br />• отладка фурнитуры.
            <br />• После комплексной регулировки окно будет лучше сохранять тепло, повысится комфорт при эксплуатации профиля и окно прослужит гораздо дольше.
          </p>
        </div>

        {/* Особенности, виды полотен, гарантии */}
        <div className="features">
          <div className="feature-box">
            <h4>Неисправность</h4>
            <p>Зачем выполнять регулировку? <br />
              <br />✔ створка заедает, клинит
              <br />✔ сквозняки, скрипит
              <br />✔ издает посторонние звуки
              <br />✔ доносится шум с улицы</p>
          </div>
          <div className="feature-box">
            <h4>Виды регулировки</h4>
            <p>✔ Простая (без разборки рамы)   </p>
            <p>✔ Сложная (расклинивание стеклопакетом)</p>

          </div>
          <div className="feature-box">
            <h4>Длительность работ               </h4>
            <p>✔ 1 выезд</p>
            <p> <strong >Время, затрачиваемое на 1 створку:</strong> </p>
            <p>✔ Простая регулировка - до 15 минут</p>
            <p>✔ Сложная - до 60 минут</p>

          </div>
          <div className="feature-box">
            <h4>Цена</h4>
            <p>✔ От 350 руб/створка.. Подробнее в прайс-листе ниже.</p>
          </div>



        </div>






        {/* Таблица цен */}
        <h3>Цены на Москитные Сетки</h3>
        <table className="price-table">
          <thead>
            <tr>
              <th>Количество, шт</th>
              <th>Цена (руб.) с замером</th>
              <th>Цена (руб.) самовывоз</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6].map((num) => (
              <tr key={num}>
                <td>{num}</td>
                <td>{num * 1800}</td>
                <td>{num * 1600}</td>
              </tr>
            ))}
          </tbody>
        </table>



        <h3>Как заказать </h3>
        <ul className="order-steps">

          {/* Как заказать */}
          <li>Выбрать тип сетки</li>
          <li>Снять замеры или вызвать мастера</li>
          <li>Связаться с нами: <b>+7 (965) 475-77-88</b></li>
          <li>Оформить заказ и согласовать стоимость</li>
        </ul>
        <div className="whyNeed">
          <h3> Когда нужна регулировка окон</h3>
          <h4>Ремонт и регулировка окон ПВХ может потребоваться в следующих случаях:</h4>
          <p>
            <br />• непрофессиональный монтаж;
            <br />• износ фурнитуры, механизмов и других элементов;
            <br />• несоблюдение правил эксплуатации;
            <br /> • перекос створки;
            <br /> • расшатывание и заклинивание ручки;
            <br /> • провисание петель;
            <br />• продувание из окна;
            <br /> • переход на зимний режим.

          </p>









        </div>

      </>
    ),
  },
  {
    id: 2, title: "Замена уплотнителя",
    content: (
      <>
        {/* Условия выезда */}
        <div className="conditions">
          <h3>Условия выезда</h3>
          <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
          <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>1 500 руб.</b></p>
        </div>


        {/* Описание и фото */}
        <div className="description">
          <img src={Ramochnie} alt="Рамочная москитная сетка" className="main-image" />
          <p>Производим замену уплотнителя в пластиковых оконных и дверных системах по цене от 110 рублей за погонный метр.<br /> Предоставляем гарантию на работу 1 год, на материал до 10 лет.
            <strong>Устраняем все виды неполадок </strong>, производим замену фурнитуры и механизмов для ПВХ окон и дверей в Краснодаре и Краснодарском крае.
          </p>

        </div>


        <div className="description1">
          <h2>
            Замена уплотнителя окон ПВХ в Краснодаре
          </h2>
          <p>
            Уплотнитель является важным элементом оконной системы. Именно он обеспечивает плотное прилегание створки к раме окна, обеспечивая тем самым герметичность, шумоизоляцию и теплосбережение.<br />
            Но лучше деать всё сразу
            <br />• восстановление геометрии окна;
            <br />• герметизация стыков;
            <br />• смазка фурнитуры и подвижных частей окна;
            <br />• отладка фурнитуры.
            <br />• После комплексной регулировки окно будет лучше сохранять тепло, повысится комфорт при эксплуатации профиля и окно прослужит гораздо дольше.
          </p>
        </div>

        {/* Особенности, виды полотен, гарантии */}
        <div className="features">
          <div className="feature-box">
            <h4>Неисправность</h4>
            <p>Изношенный уплотнитель приводит к проблемам:
              <br />
              <br />✔ продувание, сквозняки
              <br />✔ промерзание, заледенение окна
              <br />✔ появление пыли на подоконнике
              <br />✔ посторонние шумы, и нарушенная шумоизоляция
              <br />✔ образование конденсата, запотевание</p>
          </div>
          <div className="feature-box">
            <h4>Материал</h4>
            <p>✔ Мы используем качественную уплотнительную резину: </p>
            <p>Материал изготовления:</p>
            <p>✔EPDM</p>
            <p>✔TPE</p>
            <p>✔PVC</p>
            <p>Cтрана производства:</p>
            <p>✔ Россия</p>
            <p>✔ Германия</p>

          </div>
          <div className="feature-box">
            <h4>Длительность работ               </h4>
            <p>✔ 1 выезд</p>
            <p> <strong >Время, затрачиваемое на 1 створку:</strong> </p>
            <p>✔ 15 - 30 минут</p>


          </div>
          <div className="feature-box">
            <h4>Цена</h4>
            <p>✔ От 110 до 200 руб/п.м. Зависит от используемого уплотнителя.              Подробнее в прайс-листе ниже..</p>
          </div>



        </div>






        {/* Таблица цен */}
        < h3 > Цены на Москитные Сетки</h3 >
        <table className="price-table">
          <thead>
            <tr>
              <th>Количество, шт</th>
              <th>Цена (руб.) с замером</th>
              <th>Цена (руб.) самовывоз</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6].map((num) => (
              <tr key={num}>
                <td>{num}</td>
                <td>{num * 1800}</td>
                <td>{num * 1600}</td>
              </tr>
            ))}
          </tbody>
        </table>



        <h3>Как заказать </h3>
        <ul className="order-steps">

          {/* Как заказать */}
          <li>Выбрать тип сетки</li>
          <li>Снять замеры или вызвать мастера</li>
          <li>Связаться с нами: <b>+7 (918) 635-78-77</b></li>
          <li>Оформить заказ и согласовать стоимость</li>
        </ul>
        <div className="whyNeed">
          <h3>Когда нужно менять уплотнитель</h3>
          <h4>Ремонт и регулировка окон ПВХ может потребоваться в следующих случаях:</h4>
          <p>
            <br />• Срок службы оконной системы составляет 40-50 лет, в то время как эффективная работа уплотнителя длится не более 10 лет в зависимости от качества материала. Например поливинилхлоридный 3 года, ТРЕ 10 лет, EPDM 10 лет.
            <br />• Чтобы продлить срок службы уплотнителя, необходимо раз в год обрабатывать его специальной силиконовой смазкой. Без должного ухода, со временем, уплотнитель изнашивается, становится твердым и перестает выполнять свои функции. Уплотнитель одинаково быстро изнашивается и в пластиковом, и в алюминиевом, и в деревянном окне.






          </p>
        </div>

      </>
    ),
  },
  {
    id: 3, title: "Изготовление и замена стеклопакета",
    content: (
      <>
        {/* Условия выезда */}
        <div className="conditions">
          <h3>Условия выезда</h3>
          <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
          <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>1 500 руб.</b></p>
        </div>


        {/* Описание и фото */}
        <div className="description">
          <img src={Ramochnie} alt="Рамочная москитная сетка" className="main-image" />
          <p>
            выполняет ремонт и замену разбитых стеклопакетов в Краснодаре и Краснодарском крае по цене от 2500 рублей за кв.м.<br />


            <strong>Мы меняем стеклопакеты в пластиковых </strong> деревянных и алюминиевых окнах. Гарантия на все виды работ составляет один год.
          </p>

        </div>


        <div className="description1">
          <h2>
            Замена стеклопакета в Краснодаре
          </h2>
          <p>
            Профилактика и регулировка окон пвх обычно включает в себя следующие процедуры:<br />
            <br />• восстановление геометрии окна;
            <br />• герметизация стыков;
            <br /> • смазка фурнитуры и подвижных частей окна;
            <br />• отладка фурнитуры.
            <br />• После комплексной регулировки окно будет лучше сохранять тепло, повысится комфорт при эксплуатации профиля и окно прослужит гораздо дольше.
          </p>
        </div>

        {/* Особенности, виды полотен, гарантии */}
        <div className="features">
          <div className="feature-box">
            <h4>Неисправность</h4>
            <p>Зачем менять стеклопакет?
              <br />✔ лопнул из-за нарушений при монтаже
              <br />✔ кто-то разбил
              <br />✔ начал запотевать
              <br />✔ плохая шумоизоляция
              <br />✔ недостаточная теплоизоляция
            </p>
          </div>
          <div className="feature-box">
            <h4>Виды стеклопакетов</h4>
            <p>Мы устанавливаем</p>
            <p>✔ 1-, 2- и 3-камерные</p>
            <p>✔ энергосберегающие</p>
            <p>✔ мультифункциональные</p>
            <p>✔ шумоизоляционные</p>
            <p>✔ тонированные</p>
          </div>
          <div className="feature-box">
            <h4>Длительность работ    </h4>
            <p>✔ 2 выезда</p>
            <p> <strong >Время, затрачиваемое на 1 створку </strong> </p>
            <p>✔ ~ 15-60 минут</p>
          </div>
          <div className="feature-box">
            <h4>Цена</h4>
            <p>✔ От 2500 руб/створка.. Подробнее в прайс-листе ниже.</p>
          </div>



        </div>






        {/* Таблица цен */}
        <h3>Цены на Москитные Сетки</h3>
        <table className="price-table">
          <thead>
            <tr>
              <th>Количество, шт</th>
              <th>Цена (руб.) с замером</th>
              <th>Цена (руб.) самовывоз</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6].map((num) => (
              <tr key={num}>
                <td>{num}</td>
                <td>{num * 1800}</td>
                <td>{num * 1600}</td>
              </tr>
            ))}
          </tbody>
        </table>



        <h3>Как заказать </h3>
        <ul className="order-steps">

          {/* Как заказать */}
          <li>Выбрать тип сетки</li>
          <li>Снять замеры или вызвать мастера</li>
          <li>Связаться с нами: <b>+7 (918) 635-78-77</b></li>
          <li>Оформить заказ и согласовать стоимость</li>
        </ul>
        <div className="whyNeed">
          <h3> Когда нужна регулировка окон</h3>
          <h4>Ремонт и регулировка окон ПВХ может потребоваться в следующих случаях:</h4>
          <p>
            <br />• непрофессиональный монтаж;
            <br />• износ фурнитуры, механизмов и других элементов;
            <br />• несоблюдение правил эксплуатации;
            <br />• перекос створки;
            <br />• расшатывание и заклинивание ручки;
            <br />• провисание петель;
            <br />• продувание из окна;
            <br />• переход на зимний режим.
          </p>





        </div>

      </>
    ),
  },
  {
    id: 4, title: "Монтаж откосов и подоконников",
    content: "Описание регулировки фурнитуры"
  },
  { id: 5, title: "Установка и замена окон", content: "Описание регулировки фурнитуры" },
  { id: 6, title: "замена петель, ручек, гребенок", content: "Описание регулировки фурнитуры" },



];

const WindowRepair = forwardRef((props, ref) => {
  const [activeCategory, setActiveCategory] = useState(categories[0]);

  return (
    <div ref={ref} className="window-repair" >
      <h2>РЕМОНТ ПЛАСТИКОВЫХ  </h2>
      <div className="content">
        {/* Левый блок - Список категорий */}
        <div className="menu">
          {categories.map((cat) => (
            <div
              key={cat.id}
              className={`menu-item ${activeCategory.id === cat.id ? "active" : ""}`}
              onClick={() => setActiveCategory(cat)}
            >
              {cat.title}
            </div>
          ))}
        </div>

        {/* Правый блок - Контент выбранной категории */}
        <div className="display">{activeCategory.content}</div>
      </div>
    </div>
  );
});

export default WindowRepair;
