import React, { useState, useEffect } from "react";
import { useRef } from "react";
import HowWeWork from "./components/HowWeWork";
import Main from "./components/Main";
import AboutUs from "./components/AboutUs";
import WhyUs from "./components/WhyUs";
import EmergencyUnlock from "./components/EmergencyUnlock";
import Contacts from "./components/Contacts";
import FloatingContacts from "./components/FloatingContacts";
import Header from "./components/Header";
import MosquitoNets from "./components/MosquitoNets";
import WindowRepair from "./components/WindowRepair";




function App() {
  const mosquitoRef = useRef(null);
  const windowRepairRef = useRef(null);

  const sectionRefs = {
    mosquitoNets: mosquitoRef,
    windowRepair: windowRepairRef,
  };

  const handleIndicatorClick = (target) => {
    sectionRefs[target]?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="App">
      <Header />
      <FloatingContacts />
      <Main onIndicatorClick={handleIndicatorClick} />
      <EmergencyUnlock />
      <HowWeWork />
      <WindowRepair ref={windowRepairRef} />
      <MosquitoNets ref={mosquitoRef} />
      <WhyUs />
      <AboutUs />
      <Contacts />
    </div>
  );
}


export default App;
