import React, { useState } from "react";
import { FaPhone, FaTelegramPlane, FaWhatsapp, FaBars, FaTimes } from "react-icons/fa";
import "../styles.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="header">
      <div className="container header-container">
        {/* Бургер иконка */}
        <div className="burger" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <nav className={`header-nav ${menuOpen ? "active" : ""}`}>
          <a href="tel:+79654757788" className="header-link">
            <FaPhone /> +7 (965) 475-77-88
          </a>
          <a
            href="https://wa.me/79654757788"
            target="_blank"
            rel="noopener noreferrer"
            className="header-link"
          >
            <FaWhatsapp /> WhatsApp
          </a>
          <a
            href="https://t.me/Remontokonkrasnodar"
            target="_blank"
            rel="noopener noreferrer"
            className="header-link"
          >
            <FaTelegramPlane /> Telegram
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
